<template>
  <div class="profile-edit-info" v-if="!isProcessing">
    <profile-edit :user="user ? user : {}" @set-value="setPostParam" @set-blob="setBlob" />
    <v-btn class="profile-edit-info__btn" :disabled="!isFilled || isOver || !isChanged || !isCorrectedUname" depressed @click="save()">保存</v-btn>
  </div>
</template>

<script>
import ProfileEdit from '@/components/profile/form/about'

export default {
  name: 'profile_edit_info',
  components: { ProfileEdit },
  data () {
    return {
      // ポストパラメータ
      postParams: {
        icon: '',
        background: '',
        name: '',
        uname: '',
        position: '',
        profile: ''
      },
      // Blobファイル
      blobs: {
        icon: null,
        background: null
      },
      // 画像の格納先のパス名
      paths: {
        icon: '',
        background: ''
      }
    }
  },
  mounted () {
    // ユーザー名の格納
    this.postParams.uname = this.uname

    // ユーザー情報の格納
    if (this.user) {
      Object.keys(this.postParams).forEach(key => {
        if (key !== 'uname') this.postParams[key] = this.user[key]
      })
    }

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {String} ユーザー名
     */
    uname () {
      return this.$store.getters['uidUname/uname'](this.uid) || this.uid
    },
    /**
     * @return {Object} ユーザー
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {Boolean} ポストパラメータが全て埋まっているかどうか
     */
    isFilled () {
      const keys = Object.keys(this.postParams)
      return keys.every(key => this.postParams[key].length > 0)
    },
    /**
     * @return {Boolean} ポストパラメータに最大文字数を超えるものがないか
     */
    isOver () {
      const max = this.$store.getters.MAX_LENGTH.PROFILE
      return Object.keys(max).some(key => this.postParams[key].length > max[key])
    },
    /**
     * @return {Boolean} 正しいユーザー名のフォーマットかどうか
     */
    isCorrectedUname () {
      return /^[a-zA-Z0-9_]*$/.test(this.postParams.uname)
    },
    /**
     * @return {Boolean} ポストパラメータの値に変化があるか
     */
    isChanged () {
      const keys = Object.keys(this.postParams)
      return !this.user ? true : // ユーザー情報がない場合は変更ありとする
        keys.some(key => key === 'uname' ? this.postParams[key] !== this.uname : this.postParams[key] !== this.user[key])
    }
  },
  methods: {
    /**
     * ポストパラメータに値の代入
     * @param {String} key 格納する変数名
     * @param {String} value 格納する変数値
     */
    setPostParam (key, value) {
      this.postParams[key] = value
    },
    /**
     * Blobファイルの代入
     * @param {String} key 格納する変数名
     * @param {Blob} blob 格納するBlobファイル
     * @param {String} path 格納する画像の格納先のパス名
     */
    setBlob (key, blob, path) {
      this.blobs[key] = blob
      this.paths[key] = path
    },
    /**
     * 編集した情報の保存
     */
    async save () {
      this.$store.commit('setSubmitting', true)

      // ユーザー名の更新
      // 正しくユーザー名が更新されたら、ユーザー情報の更新のためにunameを削除するので別途格納しておく
      const uname = this.postParams.uname
      if (!await this.updateUname()) return

      // 画像の更新
      await this.updateImage()

      // ユーザー情報の作成または更新
      await this.updateUser()

      // 処理完了。画面遷移
      this.$store.commit('setTelop', { show: true, msg: 'プロフィール情報を更新しました', type: 'success' })
      this.$router.push({ name: 'profile', params: { uname: uname } })
    },
    /**
     * ユーザー名の更新
     * @return {Boolean} 処理が成功したかどうか
     * @todo ユーザー名の確認と作成・削除をトランザクション処理にする
     * @see https://bitbucket.org/nukumotech/qration-main/issues/147
     */
    async updateUname () {
      // 更新がない場合は終了
      if (this.uname === this.postParams.uname) return true

      // すでに存在するユーザー名でないかの確認
      const existUname = await this.$store.dispatch('uidUname/existUname', this.postParams.uname)
      if (existUname) {
        this.$store.commit('setTelop', { show: true, msg: 'ユーザー名が既に存在します', type: 'error' })
        this.$store.commit('setSubmitting', false)
        return false
      }

      // 現在のユーザー名の削除と新しいユーザー名の作成
      const promises = []
      if (this.uname !== this.uid) promises.push(this.$store.dispatch('uidUname/deleteUname', this.uname))
      promises.push(this.$store.dispatch('uidUname/createUname', { uname: this.postParams.uname, uid: this.uid }))
      await Promise.all(promises)

      // ポストパラメータからユーザー名を削除
      delete this.postParams.uname

      return true
    },
    /**
     * 画像の更新
     */
    async updateImage () {
      // promsieの結果がどの処理に紐づくものか判断するためにkeyをもたせる
      const actions = { keys: [], promises: [] }
      Object.keys(this.blobs).forEach(key => {
        if (!this.blobs[key]) return

        // 画像アップロードの関数追加
        actions.keys.push(key)
        actions.promises.push(this.$store.dispatch('cloudstorage/uploadImage', { blob: this.blobs[key], path: this.paths[key] }))

        // 既存の画像の削除
        // 削除はいつ終わっても問題ないので、非同期処理
        if (this.user) this.$store.dispatch('cloudstorage/deleteImage', this.user[key])
      })
      const urls = actions.promises.length > 0 ? await Promise.all(actions.promises) : []

      // 画像のURLの更新
      urls.forEach((url, index) => {
        this.postParams[actions.keys[index]] = url
      })
    },
    /**
     * ユーザー情報の作成または更新
     * 既存のユーザー情報がない場合は作成、それ以外は更新
     */
    async updateUser () {
      // 以下の場合は処理を終了する
      // 既存のユーザーが存在しており、かつ既存のユーザー情報からの更新が一つもない場合
      const isFinished = this.user && Object.keys(this.postParams).filter(key => this.postParams[key] !== this.user[key]).length === 0
      if (isFinished) return

      // 日時情報の追加
      this.postParams.updatedAt = new Date()
      if (!this.user) this.postParams.createdAt = new Date() // ユーザー情報の新規作成の場合

      // ユーザー情報の作成または更新
      const name = 'users/' + (this.user ? 'updateUser' : 'createUser')
      const argument = { uid: this.uid, params: this.postParams }
      await this.$store.dispatch(name, argument)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.profile-edit-info {
  width: 100vw;
  max-width: $max_width;
  padding: 0 $padding_width;
  margin: $header_height auto $footer_height auto;
  &__btn {
    display: block;
    margin: 20px 0 20px auto;
    font-size: 1.2rem;
    border-radius: 15px;
    &.theme--light.v-btn {
      color: $white_color;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: auto;
      padding: 7px 23px;
    }
    &.theme--light.v-btn.v-btn--disabled {
      color: $white_color !important;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_lighten_color !important;
    }
  }
}
</style>
