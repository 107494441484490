<template>
  <div class="profile-form-about">
    <v-img class="profile-form-about__background" :src="background">
      <v-icon class="profile-form-about__camera">camera_alt</v-icon>
      <label class="profile-form-about__label" for="background" />
      <input id="background" class="profile-form-about__file" @change="selectPicture('background', $event)" type="file" accept="image/*" />
    </v-img>
    <v-img class="profile-form-about__icon" :src="icon">
      <v-icon class="profile-form-about__camera--small">camera_alt</v-icon>
      <label class="profile-form-about__label" for="icon" />
      <input id="icon" class="profile-form-about__file" @change="selectPicture('icon', $event)" type="file" accept="image/*" />
    </v-img>
    <h2 class="profile-form-about__title">名前</h2>
    <input class="profile-form-about__input" type="text" placeholder="名前を入力してください"
           v-model="name" @input="setValue('name', name)" />
    <p class="profile-form-about__rest">
      <span :class="{ 'profile-form-about__rest__over': name.length > max['name'] }">{{ name.length }}</span>/{{ max['name'] }}
    </p>
    <h2 class="profile-form-about__title">ユーザー名</h2>
    <input class="profile-form-about__input" type="text" placeholder="ユーザー名"
           v-model="uname" @input="setValue('uname', uname)" />
    <p class="profile-form-about__rest">
      <span v-if="!isCorrectedUname" class="profile-form-about__rest__condition">「英数字」、「_」のみ使用できます</span>
      <span :class="{ 'profile-form-about__rest__over': uname.length > max['uname'] }">{{ uname.length }}</span>/{{ max['uname'] }}
    </p>
    <h2 class="profile-form-about__title">肩書き</h2>
    <input class="profile-form-about__input" type="text" placeholder="肩書きを入力してください"
           v-model="position" @input="setValue('position', position)" />
    <p class="profile-form-about__rest">
      <span :class="{ 'profile-form-about__rest__over': position.length > max['position'] }">{{ position.length }}</span>/{{ max['position'] }}
    </p>
    <h2 class="profile-form-about__title">プロフィール</h2>
    <textarea class="profile-form-about__textarea" placeholder="プロフィール情報を入力してください"
              v-model="profile" @input="setValue('profile', profile)" />
    <p class="profile-form-about__rest">
      <span :class="{ 'profile-form-about__rest__over': profile.length > max['profile'] }">{{ profile.length }}</span>/{{ max['profile'] }}
    </p>
  </div>
</template>

<script>
import image from '@/assets/lib/image'

export default {
  mixins: [image],
  props: {
    // ユーザー情報
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      // アイコン
      icon: '',
      // 背景画像
      background: '',
      // 名前
      name: '',
      // ユーザー名
      uname: '',
      // 肩書き
      position: '',
      // プロフィール文
      profile: ''
    }
  },
  mounted () {
    // ユーザー情報の格納
    Object.keys(this.$data).forEach(key => {
      if (this.user[key]) this.$data[key] = this.user[key]
    })

    // ユーザー名は別途格納する
    // ユーザー名が未格納な場合はuidを格納する
    this.uname = this.$store.getters['uidUname/uname'](this.uid) || this.uid
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} 最大の長さの一覧
     */
    max () {
      return this.$store.getters.MAX_LENGTH.PROFILE
    },
    /**
     * @return {Boolean} 正しいユーザー名のフォーマットかどうか
     */
    isCorrectedUname () {
      return /^[a-zA-Z0-9_]*$/.test(this.uname)
    }
  },
  methods: {
    /**
     * 画像の選択
     * @param {String} name 選択した画像の種類 [ 'background' | 'icon' ]
     * @param {Object} event 発火したイベント
     */
    async selectPicture (name, event) {
      event.preventDefault()

      // 保存先URLパスとファイルの格納
      const file = event.target.files[0]
      const path = '/users/' + this.uid + '/' + name + '/'
      const results = await this.doImgProcess(file, path)

      this.$data[name] = results.url
      this.setValue(name, results.url)
      this.$emit('set-blob', name, results.blob, results.path)
    },
    /**
     * 記入された値を親の変数に格納
     * @param {String} key 格納する変数名
     * @param {String} value 格納する変数値
     */
    setValue (key, value) {
      this.$emit('set-value', key, value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
@import '@/assets/sass/fontfamily.scss';

.profile-form-about {
  &__background {
    position: relative;
    width: 100vw;
    height: 200px;
    margin-left: -$padding_width;
    background-color: $light_gray_color;
    &.v-responsive {
      max-width: $max_width;
    }
  }
  &__icon {
    position: relative;
    flex: initial;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    background-color: $light_gray_color;
    border: 4px solid $white_color;
    border-radius: 50%;
  }
  &__camera {
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: $material-outlined;
    font-size: 3.5rem;
    color: $dark_gray_color;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    &--small {
      @extend .profile-form-about__camera;
      font-size: 2.5rem;
    }
  }
  &__label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__file {
    display: none;
  }
  &__title {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: normal;
    &:first-of-type {
      margin-top: 20px;
    }
  }
  &__input {
    width: 100%;
    padding: 5px 0;
    font-size: 1.6rem;
    color: $black_color;
    border-bottom: 1px solid $black_color;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: $gray_color;
    }
  }
  &__textarea {
    width: 100%;
    height: 100px;
    padding: 5px 0;
    font-size: 1.6rem;
    color: $black_color;
    border-bottom: 1px solid $black_color;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: $gray_color;
    }
  }
  &__rest {
    margin: 5px 0 0 0;
    color: $dark_gray_color;
    text-align: right;
    &__condition {
      margin-right: 5px;
      color: $red_color;
    }
    &__over {
      color: $red_color;
    }
  }
}
</style>
