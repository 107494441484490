/**
 * original founction library
 * [usage]
 * import image from '@/assets/lib/image'
 * mixins: [image]
 */
import Vue from 'vue'

import string from '@/assets/lib/string'

/**
 * @see https://github.com/blueimp/JavaScript-Load-Image
 */
import loadImage from 'blueimp-load-image'

export default Vue.extend({
  mixins: [string],
  methods: {
    /**
     * FileをURLに変換する
     * @param {File} file 変換したいファイル情報
     * @param {String} path 格納先のパス名
     * @return {Object} { blob: Blobファイル, path: 格納先のパス名, url: ローカルのURL }
     * @todo async/awaitを他と合わせた記述方法に修正する
     */
    doImgProcess (file, path) {
      return new Promise(resolve => {
        loadImage.parseMetaData(file, data => {
          // exif情報から回転情報を取得
          const exif = data.exif ? data.exif.get('Orientation') : null

          // リサイズ用のオプション
          const options = {
            maxHeight: 1024,
            maxWidth: 1024,
            canvas: true
          }
          if (exif) options.orientation = exif

          // 画像容量が大きい場合、cloud functionsのリクエストでサイズオーバーになるので、品質を落とす
          const compressionRatio = file.size >= 1000000 ? 1000000 / file.size : 1

          // FileをURLに変換
          loadImage(
            file,
            canvas => {
              canvas.toBlob(blob => {
                resolve({
                  blob: blob,
                  path: path + this.getUniqueStr() + file.type.replace('image/', '.'),
                  url: window.URL.createObjectURL(blob)
                })
              },
              file.type,
              compressionRatio
              )
            },
            options
          )
        })
      })
    }
  }
})
